var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};
!function (e, t) {
  exports = t();
}("undefined" != typeof self ? self : exports, function () {
  return function (e) {
    function t(r) {
      if (i[r]) return i[r].exports;
      var n = i[r] = {
        i: r,
        l: !1,
        exports: {}
      };
      return e[r].call(n.exports, n, n.exports, t), n.l = !0, n.exports;
    }

    var i = {};
    return t.m = e, t.c = i, t.d = function (e, i, r) {
      t.o(e, i) || Object.defineProperty(e, i, {
        configurable: !1,
        enumerable: !0,
        get: r
      });
    }, t.n = function (e) {
      var i = e && e.__esModule ? function () {
        return e.default;
      } : function () {
        return e;
      };
      return t.d(i, "a", i), i;
    }, t.o = function (e, t) {
      return Object.prototype.hasOwnProperty.call(e, t);
    }, t.p = "", t(t.s = 0);
  }([function (e, t, i) {
    "use strict";

    function r(e, t) {
      if (!(e instanceof t)) throw new TypeError("Cannot call a class as a function");
    }

    Object.defineProperty(t, "__esModule", {
      value: !0
    });

    var n = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (e) {
      return typeof e;
    } : function (e) {
      return e && "function" == typeof Symbol && e.constructor === Symbol && e !== Symbol.prototype ? "symbol" : typeof e;
    },
        s = function () {
      function e(e, t) {
        for (var i = 0; i < t.length; i++) {
          var r = t[i];
          r.enumerable = r.enumerable || !1, r.configurable = !0, "value" in r && (r.writable = !0), Object.defineProperty(e, r.key, r);
        }
      }

      return function (t, i, r) {
        return i && e(t.prototype, i), r && e(t, r), t;
      };
    }(),
        l = function () {
      function e(t) {
        var i = this || _global;
        if (r(this || _global, e), (this || _global).config = e.mergeSettings(t), (this || _global).selector = "string" == typeof (this || _global).config.selector ? document.querySelector((this || _global).config.selector) : (this || _global).config.selector, null === (this || _global).selector) throw new Error("Something wrong with your selector \uD83D\uDE2D");
        this.resolveSlidesNumber(), (this || _global).selectorWidth = (this || _global).selector.offsetWidth, (this || _global).innerElements = [].slice.call((this || _global).selector.children), (this || _global).currentSlide = (this || _global).config.loop ? (this || _global).config.startIndex % (this || _global).innerElements.length : Math.max(0, Math.min((this || _global).config.startIndex, (this || _global).innerElements.length - (this || _global).perPage)), (this || _global).transformProperty = e.webkitOrNot(), ["resizeHandler", "touchstartHandler", "touchendHandler", "touchmoveHandler", "mousedownHandler", "mouseupHandler", "mouseleaveHandler", "mousemoveHandler", "clickHandler"].forEach(function (e) {
          i[e] = i[e].bind(i);
        }), this.init();
      }

      return s(e, [{
        key: "attachEvents",
        value: function () {
          window.addEventListener("resize", (this || _global).resizeHandler), (this || _global).config.draggable && ((this || _global).pointerDown = !1, (this || _global).drag = {
            startX: 0,
            endX: 0,
            startY: 0,
            letItGo: null,
            preventClick: !1
          }, (this || _global).selector.addEventListener("touchstart", (this || _global).touchstartHandler), (this || _global).selector.addEventListener("touchend", (this || _global).touchendHandler), (this || _global).selector.addEventListener("touchmove", (this || _global).touchmoveHandler), (this || _global).selector.addEventListener("mousedown", (this || _global).mousedownHandler), (this || _global).selector.addEventListener("mouseup", (this || _global).mouseupHandler), (this || _global).selector.addEventListener("mouseleave", (this || _global).mouseleaveHandler), (this || _global).selector.addEventListener("mousemove", (this || _global).mousemoveHandler), (this || _global).selector.addEventListener("click", (this || _global).clickHandler));
        }
      }, {
        key: "detachEvents",
        value: function () {
          window.removeEventListener("resize", (this || _global).resizeHandler), (this || _global).selector.removeEventListener("touchstart", (this || _global).touchstartHandler), (this || _global).selector.removeEventListener("touchend", (this || _global).touchendHandler), (this || _global).selector.removeEventListener("touchmove", (this || _global).touchmoveHandler), (this || _global).selector.removeEventListener("mousedown", (this || _global).mousedownHandler), (this || _global).selector.removeEventListener("mouseup", (this || _global).mouseupHandler), (this || _global).selector.removeEventListener("mouseleave", (this || _global).mouseleaveHandler), (this || _global).selector.removeEventListener("mousemove", (this || _global).mousemoveHandler), (this || _global).selector.removeEventListener("click", (this || _global).clickHandler);
        }
      }, {
        key: "init",
        value: function () {
          this.attachEvents(), (this || _global).selector.style.overflow = "hidden", (this || _global).selector.style.direction = (this || _global).config.rtl ? "rtl" : "ltr", this.buildSliderFrame(), (this || _global).config.onInit.call(this || _global);
        }
      }, {
        key: "buildSliderFrame",
        value: function () {
          var e = (this || _global).selectorWidth / (this || _global).perPage,
              t = (this || _global).config.loop ? (this || _global).innerElements.length + 2 * (this || _global).perPage : (this || _global).innerElements.length;
          (this || _global).sliderFrame = document.createElement("div"), (this || _global).sliderFrame.style.width = e * t + "px", this.enableTransition(), (this || _global).config.draggable && ((this || _global).selector.style.cursor = "-webkit-grab");
          var i = document.createDocumentFragment();
          if ((this || _global).config.loop) for (var r = (this || _global).innerElements.length - (this || _global).perPage; r < (this || _global).innerElements.length; r++) {
            var n = this.buildSliderFrameItem((this || _global).innerElements[r].cloneNode(!0));
            i.appendChild(n);
          }

          for (var s = 0; s < (this || _global).innerElements.length; s++) {
            var l = this.buildSliderFrameItem((this || _global).innerElements[s]);
            i.appendChild(l);
          }

          if ((this || _global).config.loop) for (var o = 0; o < (this || _global).perPage; o++) {
            var a = this.buildSliderFrameItem((this || _global).innerElements[o].cloneNode(!0));
            i.appendChild(a);
          }
          (this || _global).sliderFrame.appendChild(i), (this || _global).selector.innerHTML = "", (this || _global).selector.appendChild((this || _global).sliderFrame), this.slideToCurrent();
        }
      }, {
        key: "buildSliderFrameItem",
        value: function (e) {
          var t = document.createElement("div");
          return t.style.cssFloat = (this || _global).config.rtl ? "right" : "left", t.style.float = (this || _global).config.rtl ? "right" : "left", t.style.width = ((this || _global).config.loop ? 100 / ((this || _global).innerElements.length + 2 * (this || _global).perPage) : 100 / (this || _global).innerElements.length) + "%", t.appendChild(e), t;
        }
      }, {
        key: "resolveSlidesNumber",
        value: function () {
          if ("number" == typeof (this || _global).config.perPage) (this || _global).perPage = (this || _global).config.perPage;else if ("object" === n((this || _global).config.perPage)) {
            (this || _global).perPage = 1;

            for (var e in (this || _global).config.perPage) window.innerWidth >= e && ((this || _global).perPage = (this || _global).config.perPage[e]);
          }
        }
      }, {
        key: "prev",
        value: function () {
          var e = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : 1,
              t = arguments[1];

          if (!((this || _global).innerElements.length <= (this || _global).perPage)) {
            var i = (this || _global).currentSlide;

            if ((this || _global).config.loop) {
              if ((this || _global).currentSlide - e < 0) {
                this.disableTransition();
                var r = (this || _global).currentSlide + (this || _global).innerElements.length,
                    n = (this || _global).perPage,
                    s = r + n,
                    l = ((this || _global).config.rtl ? 1 : -1) * s * ((this || _global).selectorWidth / (this || _global).perPage),
                    o = (this || _global).config.draggable ? (this || _global).drag.endX - (this || _global).drag.startX : 0;
                (this || _global).sliderFrame.style[(this || _global).transformProperty] = "translate3d(" + (l + o) + "px, 0, 0)", (this || _global).currentSlide = r - e;
              } else (this || _global).currentSlide = (this || _global).currentSlide - e;
            } else (this || _global).currentSlide = Math.max((this || _global).currentSlide - e, 0);

            i !== (this || _global).currentSlide && (this.slideToCurrent((this || _global).config.loop), (this || _global).config.onChange.call(this || _global), t && t.call(this || _global));
          }
        }
      }, {
        key: "next",
        value: function () {
          var e = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : 1,
              t = arguments[1];

          if (!((this || _global).innerElements.length <= (this || _global).perPage)) {
            var i = (this || _global).currentSlide;

            if ((this || _global).config.loop) {
              if ((this || _global).currentSlide + e > (this || _global).innerElements.length - (this || _global).perPage) {
                this.disableTransition();
                var r = (this || _global).currentSlide - (this || _global).innerElements.length,
                    n = (this || _global).perPage,
                    s = r + n,
                    l = ((this || _global).config.rtl ? 1 : -1) * s * ((this || _global).selectorWidth / (this || _global).perPage),
                    o = (this || _global).config.draggable ? (this || _global).drag.endX - (this || _global).drag.startX : 0;
                (this || _global).sliderFrame.style[(this || _global).transformProperty] = "translate3d(" + (l + o) + "px, 0, 0)", (this || _global).currentSlide = r + e;
              } else (this || _global).currentSlide = (this || _global).currentSlide + e;
            } else (this || _global).currentSlide = Math.min((this || _global).currentSlide + e, (this || _global).innerElements.length - (this || _global).perPage);

            i !== (this || _global).currentSlide && (this.slideToCurrent((this || _global).config.loop), (this || _global).config.onChange.call(this || _global), t && t.call(this || _global));
          }
        }
      }, {
        key: "disableTransition",
        value: function () {
          (this || _global).sliderFrame.style.webkitTransition = "all 0ms " + (this || _global).config.easing, (this || _global).sliderFrame.style.transition = "all 0ms " + (this || _global).config.easing;
        }
      }, {
        key: "enableTransition",
        value: function () {
          (this || _global).sliderFrame.style.webkitTransition = "all " + (this || _global).config.duration + "ms " + (this || _global).config.easing, (this || _global).sliderFrame.style.transition = "all " + (this || _global).config.duration + "ms " + (this || _global).config.easing;
        }
      }, {
        key: "goTo",
        value: function (e, t) {
          if (!((this || _global).innerElements.length <= (this || _global).perPage)) {
            var i = (this || _global).currentSlide;
            (this || _global).currentSlide = (this || _global).config.loop ? e % (this || _global).innerElements.length : Math.min(Math.max(e, 0), (this || _global).innerElements.length - (this || _global).perPage), i !== (this || _global).currentSlide && (this.slideToCurrent(), (this || _global).config.onChange.call(this || _global), t && t.call(this || _global));
          }
        }
      }, {
        key: "slideToCurrent",
        value: function (e) {
          var t = this || _global,
              i = (this || _global).config.loop ? (this || _global).currentSlide + (this || _global).perPage : (this || _global).currentSlide,
              r = ((this || _global).config.rtl ? 1 : -1) * i * ((this || _global).selectorWidth / (this || _global).perPage);
          e ? requestAnimationFrame(function () {
            requestAnimationFrame(function () {
              t.enableTransition(), t.sliderFrame.style[t.transformProperty] = "translate3d(" + r + "px, 0, 0)";
            });
          }) : (this || _global).sliderFrame.style[(this || _global).transformProperty] = "translate3d(" + r + "px, 0, 0)";
        }
      }, {
        key: "updateAfterDrag",
        value: function () {
          var e = ((this || _global).config.rtl ? -1 : 1) * ((this || _global).drag.endX - (this || _global).drag.startX),
              t = Math.abs(e),
              i = (this || _global).config.multipleDrag ? Math.ceil(t / ((this || _global).selectorWidth / (this || _global).perPage)) : 1,
              r = e > 0 && (this || _global).currentSlide - i < 0,
              n = e < 0 && (this || _global).currentSlide + i > (this || _global).innerElements.length - (this || _global).perPage;
          e > 0 && t > (this || _global).config.threshold && (this || _global).innerElements.length > (this || _global).perPage ? this.prev(i) : e < 0 && t > (this || _global).config.threshold && (this || _global).innerElements.length > (this || _global).perPage && this.next(i), this.slideToCurrent(r || n);
        }
      }, {
        key: "resizeHandler",
        value: function () {
          this.resolveSlidesNumber(), (this || _global).currentSlide + (this || _global).perPage > (this || _global).innerElements.length && ((this || _global).currentSlide = (this || _global).innerElements.length <= (this || _global).perPage ? 0 : (this || _global).innerElements.length - (this || _global).perPage), (this || _global).selectorWidth = (this || _global).selector.offsetWidth, this.buildSliderFrame();
        }
      }, {
        key: "clearDrag",
        value: function () {
          (this || _global).drag = {
            startX: 0,
            endX: 0,
            startY: 0,
            letItGo: null,
            preventClick: (this || _global).drag.preventClick
          };
        }
      }, {
        key: "touchstartHandler",
        value: function (e) {
          -1 !== ["TEXTAREA", "OPTION", "INPUT", "SELECT"].indexOf(e.target.nodeName) || (e.stopPropagation(), (this || _global).pointerDown = !0, (this || _global).drag.startX = e.touches[0].pageX, (this || _global).drag.startY = e.touches[0].pageY);
        }
      }, {
        key: "touchendHandler",
        value: function (e) {
          e.stopPropagation(), (this || _global).pointerDown = !1, this.enableTransition(), (this || _global).drag.endX && this.updateAfterDrag(), this.clearDrag();
        }
      }, {
        key: "touchmoveHandler",
        value: function (e) {
          if (e.stopPropagation(), null === (this || _global).drag.letItGo && ((this || _global).drag.letItGo = Math.abs((this || _global).drag.startY - e.touches[0].pageY) < Math.abs((this || _global).drag.startX - e.touches[0].pageX)), (this || _global).pointerDown && (this || _global).drag.letItGo) {
            e.preventDefault(), (this || _global).drag.endX = e.touches[0].pageX, (this || _global).sliderFrame.style.webkitTransition = "all 0ms " + (this || _global).config.easing, (this || _global).sliderFrame.style.transition = "all 0ms " + (this || _global).config.easing;
            var t = (this || _global).config.loop ? (this || _global).currentSlide + (this || _global).perPage : (this || _global).currentSlide,
                i = t * ((this || _global).selectorWidth / (this || _global).perPage),
                r = (this || _global).drag.endX - (this || _global).drag.startX,
                n = (this || _global).config.rtl ? i + r : i - r;
            (this || _global).sliderFrame.style[(this || _global).transformProperty] = "translate3d(" + ((this || _global).config.rtl ? 1 : -1) * n + "px, 0, 0)";
          }
        }
      }, {
        key: "mousedownHandler",
        value: function (e) {
          -1 !== ["TEXTAREA", "OPTION", "INPUT", "SELECT"].indexOf(e.target.nodeName) || (e.preventDefault(), e.stopPropagation(), (this || _global).pointerDown = !0, (this || _global).drag.startX = e.pageX);
        }
      }, {
        key: "mouseupHandler",
        value: function (e) {
          e.stopPropagation(), (this || _global).pointerDown = !1, (this || _global).selector.style.cursor = "-webkit-grab", this.enableTransition(), (this || _global).drag.endX && this.updateAfterDrag(), this.clearDrag();
        }
      }, {
        key: "mousemoveHandler",
        value: function (e) {
          if (e.preventDefault(), (this || _global).pointerDown) {
            "A" === e.target.nodeName && ((this || _global).drag.preventClick = !0), (this || _global).drag.endX = e.pageX, (this || _global).selector.style.cursor = "-webkit-grabbing", (this || _global).sliderFrame.style.webkitTransition = "all 0ms " + (this || _global).config.easing, (this || _global).sliderFrame.style.transition = "all 0ms " + (this || _global).config.easing;
            var t = (this || _global).config.loop ? (this || _global).currentSlide + (this || _global).perPage : (this || _global).currentSlide,
                i = t * ((this || _global).selectorWidth / (this || _global).perPage),
                r = (this || _global).drag.endX - (this || _global).drag.startX,
                n = (this || _global).config.rtl ? i + r : i - r;
            (this || _global).sliderFrame.style[(this || _global).transformProperty] = "translate3d(" + ((this || _global).config.rtl ? 1 : -1) * n + "px, 0, 0)";
          }
        }
      }, {
        key: "mouseleaveHandler",
        value: function (e) {
          (this || _global).pointerDown && ((this || _global).pointerDown = !1, (this || _global).selector.style.cursor = "-webkit-grab", (this || _global).drag.endX = e.pageX, (this || _global).drag.preventClick = !1, this.enableTransition(), this.updateAfterDrag(), this.clearDrag());
        }
      }, {
        key: "clickHandler",
        value: function (e) {
          (this || _global).drag.preventClick && e.preventDefault(), (this || _global).drag.preventClick = !1;
        }
      }, {
        key: "remove",
        value: function (e, t) {
          if (e < 0 || e >= (this || _global).innerElements.length) throw new Error("Item to remove doesn't exist \uD83D\uDE2D");
          var i = e < (this || _global).currentSlide,
              r = (this || _global).currentSlide + (this || _global).perPage - 1 === e;
          (i || r) && (this || _global).currentSlide--, (this || _global).innerElements.splice(e, 1), this.buildSliderFrame(), t && t.call(this || _global);
        }
      }, {
        key: "insert",
        value: function (e, t, i) {
          if (t < 0 || t > (this || _global).innerElements.length + 1) throw new Error("Unable to inset it at this index \uD83D\uDE2D");
          if (-1 !== (this || _global).innerElements.indexOf(e)) throw new Error("The same item in a carousel? Really? Nope \uD83D\uDE2D");
          var r = t <= (this || _global).currentSlide > 0 && (this || _global).innerElements.length;
          (this || _global).currentSlide = r ? (this || _global).currentSlide + 1 : (this || _global).currentSlide, (this || _global).innerElements.splice(t, 0, e), this.buildSliderFrame(), i && i.call(this || _global);
        }
      }, {
        key: "prepend",
        value: function (e, t) {
          this.insert(e, 0), t && t.call(this || _global);
        }
      }, {
        key: "append",
        value: function (e, t) {
          this.insert(e, (this || _global).innerElements.length + 1), t && t.call(this || _global);
        }
      }, {
        key: "destroy",
        value: function () {
          var e = arguments.length > 0 && void 0 !== arguments[0] && arguments[0],
              t = arguments[1];

          if (this.detachEvents(), (this || _global).selector.style.cursor = "auto", e) {
            for (var i = document.createDocumentFragment(), r = 0; r < (this || _global).innerElements.length; r++) i.appendChild((this || _global).innerElements[r]);

            (this || _global).selector.innerHTML = "", (this || _global).selector.appendChild(i), (this || _global).selector.removeAttribute("style");
          }

          t && t.call(this || _global);
        }
      }], [{
        key: "mergeSettings",
        value: function (e) {
          var t = {
            selector: ".siema",
            duration: 200,
            easing: "ease-out",
            perPage: 1,
            startIndex: 0,
            draggable: !0,
            multipleDrag: !0,
            threshold: 20,
            loop: !1,
            rtl: !1,
            onInit: function () {},
            onChange: function () {}
          },
              i = e;

          for (var r in i) t[r] = i[r];

          return t;
        }
      }, {
        key: "webkitOrNot",
        value: function () {
          return "string" == typeof document.documentElement.style.transform ? "transform" : "WebkitTransform";
        }
      }]), e;
    }();

    t.default = l, e.exports = t.default;
  }]);
});
export default exports;
export const Siema = exports.Siema;